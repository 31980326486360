import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import Breadcrumbs from '../components/Breadcrumbs';
import Layout from '../components/Layout';
import Button from '../components/Button';
import OverlayBox from '../components/OverlayBox';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { parseQuery } from '../utils/parseQuery';
import axios from 'axios';
import Plus from '../components/Plus';
import { colors, do_url } from '../utils/siteVars';
import ServicelogCard from '../components/ServiceLogCard';
import DateInput from '../components/DateInput';

const StyledServicelogsPage = styled.div`
  .technician {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
    .remove-technician {
      cursor: pointer;
    }
    p {
      margin-right: 0.5rem;
    }
  }
  .strong {
    font-weight: 700;
  }
  .row {
    max-width: 800px;
    p {
      font-size: 0.8rem;
    }
    select,
    input {
      padding: 0.5rem;
      font-size: 0.8rem;
    }
    .first-section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0.5rem;
    }
    .second-section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 0.5rem;
    }
  }
  .card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 20px;
    gap: 1rem;
    &.card-headers {
      padding: 1rem;
      p {
        font-weight: 700;
      }
    }
  }
  @media (max-width: 1000px) {
    .card-grid {
      grid-template-columns: 1fr 1fr 20px;
      p {
        display: none;
        &.mobile {
          display: initial;
        }
      }
    }
    .row {
      .second-section {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }
`;

const ServicelogsPage = ({ location }) => {
  //Static
  const services = ['Insp', 'Kont', 'MR', 'RR'];

  const initialRow = {
    reservoir: '',
    date: '',
    service: '',
    serviceFreeText: '',
    establishmentTimeHours: '',
    cleaningHours: '',
    stillStandHours: '',
    filter125: '',
    filter200: '',
    scandFilter: '',
    membrane125: '',
    membrane200: '',
    signTest: '',
    sealMeters: '',
  };

  const initialFormData = {
    startDate: '',
    endDate: '',
    hotelNights: '',
    miles: '',
    technicians: [],
    rows: [],
  };

  //Global
  let query = { name: '' };
  if (typeof window !== 'undefined') {
    query = parseQuery(location);
  }
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  //States
  const [logs, setLogs] = useState([]);
  const [users, setUsers] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [formActive, setFormActive] = useState(false);
  const [deleteLogModal, setDeleteLogModal] = useState({
    active: false,
    log: null,
  });

  //Functions
  const getLogs = () => {
    axios
      .get(`${process.env.GATSBY_BACKEND_API_URL}servicelogs/${query.id}`, {
        headers: {
          Authorization: 'Bearer ' + state.token,
        },
      })
      .then((res) => {
        setLogs(res.data);
        dispatch({ type: 'LOADING', value: false });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: 'LOADING', value: false });
      });
  };

  const getUsers = () => {
    axios
      .get(
        `${process.env.GATSBY_BACKEND_API_URL}users/auth/all-users/minimal`,
        {
          headers: {
            Authorization: 'Bearer ' + state.token,
          },
        }
      )
      .then((res) => {
        setUsers(
          res.data.filter(
            (u) => u.lastName !== 'Media' && u.lastName !== 'Hellberg'
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCustomer = () => {
    axios
      .get(
        `${process.env.GATSBY_BACKEND_API_URL}customers/single/${query.id}`,
        {
          headers: {
            Authorization: 'Bearer ' + state.token,
          },
        }
      )
      .then((res) => {
        if (res.data.linkedReservoirIds?.length) {
          axios
            .post(
              `${process.env.GATSBY_BACKEND_API_URL}customers/linked-reservoirs`,
              { ids: res.data.linkedReservoirIds },
              {
                headers: {
                  Authorization: 'Bearer ' + state.token,
                },
              }
            )
            .then(({ data: linkedReservoirs }) => {
              const allReservoirs = res.data.reservoirs.concat(
                linkedReservoirs
              );
              setCustomer({
                ...res.data,
                reservoirs: allReservoirs.map((r) => {
                  return {
                    id: r._id,
                    name: r.basicInformation.name,
                  };
                }),
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setCustomer({
            ...res.data,
            reservoirs: res.data.reservoirs.map((r) => {
              return {
                id: r._id,
                name: r.basicInformation.name,
              };
            }),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: 'LOADING', value: true });
    let body;
    if (formData._id) {
      body = {
        ...formData,
        technicians: formData.technicians.map((t) => t._id),
      };
      axios
        .post(
          `${process.env.GATSBY_BACKEND_API_URL}servicelogs/${query.id}/${formData._id}`,
          body,
          {
            headers: {
              Authorization: 'Bearer ' + state.token,
            },
          }
        )
        .then(() => {
          dispatch({ type: 'ALERT', content: 'Serviceloggen uppdaterades' });
          setFormActive(false);
          getLogs();
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: 'LOADING', value: false });
          dispatch({ type: 'ALERT', content: 'Något gick fel' });
        });

      dispatch({ type: 'LOADING', value: false });
    } else {
      body = {
        ...formData,
        technicians: formData.technicians.map((t) => t._id),
        createdBy: state.user._id,
        customer: query.id,
      };
      axios
        .post(
          `${process.env.GATSBY_BACKEND_API_URL}servicelogs/${query.id}`,
          body,
          {
            headers: {
              Authorization: 'Bearer ' + state.token,
            },
          }
        )
        .then(() => {
          dispatch({ type: 'ALERT', content: 'Serviceloggen skapades' });
          setFormActive(false);
          setFormData(initialFormData);
          getLogs();
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: 'LOADING', value: false });
          dispatch({ type: 'ALERT', content: 'Något gick fel' });
        });
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeRow = (e, i) => {
    const tempArr = Array.from(formData.rows);
    tempArr[i][e.target.name] = e.target.value;
    setFormData({
      ...formData,
      rows: tempArr,
    });
  };

  const handleChangeRowReservoir = (e, i) => {
    const tempArr = Array.from(formData.rows);
    tempArr[i][e.target.name] = JSON.parse(e.target.value);
    setFormData({
      ...formData,
      rows: tempArr,
    });
  };

  const addRow = () => {
    setFormData({
      ...formData,
      rows: [...formData.rows, initialRow],
    });
  };

  const deleteRow = (i) => {
    const tempArr = Array.from(formData.rows);
    tempArr.splice(i, 1);
    setFormData({
      ...formData,
      rows: tempArr,
    });
  };

  const addTechnician = (e) => {
    setFormData({
      ...formData,
      technicians: [...formData.technicians, JSON.parse(e.target.value)],
    });
  };

  const removeTechnician = (i) => {
    const tempArr = Array.from(formData.technicians);
    tempArr.splice(i, 1);
    setFormData({
      ...formData,
      technicians: tempArr,
    });
  };

  const deleteLogInit = (log) => {
    setDeleteLogModal({
      active: true,
      log,
    });
  };

  const deleteLog = () => {
    dispatch({ type: 'LOADING', value: true });
    axios
      .delete(
        `${process.env.GATSBY_BACKEND_API_URL}servicelogs/${deleteLogModal.log._id}`,
        {
          headers: {
            Authorization: 'Bearer ' + state.token,
          },
        }
      )
      .then(() => {
        dispatch({ type: 'ALERT', content: 'Serviceloggen raderades' });
        setDeleteLogModal({
          ...deleteLogModal,
          active: false,
        });
        axios
          .delete(
            `${do_url}servicelogs/${
              state.token
            }/delete-log/${customer.customerInformation.name
              .split(' ')
              .join('_')}/${deleteLogModal.log._id}`
          )
          .then(() => {})
          .catch((e) => {
            console.log(e);
          });
        getLogs();
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: 'LOADING', value: false });
      });
  };

  const editLogInit = (log) => {
    setFormData(log);
    setFormActive(true);
  };

  const exportLog = (log, save) => {
    dispatch({ type: 'LOADING', value: true });
    const body = {
      ...log,
      technicians: log.technicians.map((t) => {
        return {
          ...t,
          notifications: null,
        };
      }),
    };
    axios
      .post(
        `${do_url}servicelogs/${
          state.token
        }/create-log/${customer.customerInformation.name.split(' ').join('_')}`,
        body
      )
      .then((res) => {
        const arrayBufferView = new Uint8Array(res.data.data);
        const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
        const urlCreator = window.URL || window.webkitURL;
        const url = urlCreator.createObjectURL(blob);
        if (save) {
          const a = document.createElement('a');
          a.href = url;
          a.download = `${
            customer.customerInformation.name
          }_Servicelogg_${new Date().getFullYear()}.pdf`;
          a.click();
        } else {
          window.open(url);
        }
        dispatch({ type: 'LOADING', value: false });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: 'ALERT', content: 'Något gick fel' });
        dispatch({ type: 'LOADING', value: false });
      });
  };

  //Effects
  useEffect(() => {
    dispatch({ type: 'LOADING', value: true });
    if (state.token) {
      getUsers();
      getCustomer();
      getLogs();
    }
    //eslint-disable-next-line
  }, [state.token]);

  return (
    <Layout>
      <StyledServicelogsPage>
        <h1 className="page-header">Servicelogg</h1>
        <Breadcrumbs
          crumbs={[
            { p: 'Hem', to: '/home' },
            { p: 'Kunder', to: '/customers' },
            {
              p: query.name.split('_').join(' '),
              to: `/customers/single/?id=${query.id}`,
            },
            { p: 'Serviceloggar' },
          ]}
          rightContent={
            <Button
              small
              onClick={() => {
                setFormData(initialFormData);
                setFormActive(true);
              }}
            >
              Skapa servicelogg
            </Button>
          }
        />
        <div className="content">
          <div className="card-headers card-grid">
            <p className="mobile">Startdatum</p>
            <p>Slutdatum</p>
            <p>Ansvariga tekniker</p>
            <p className="mobile">Antal rader</p>
          </div>
          {logs.map((log) => (
            <ServicelogCard
              key={log._id}
              log={log}
              deleteLog={deleteLogInit}
              editLog={editLogInit}
              exportLog={exportLog}
            />
          ))}
        </div>
        <OverlayBox
          active={formActive}
          close={() => setFormActive(false)}
          header="Skapa servicelogg"
        >
          <form onSubmit={handleSubmit}>
            <p className="m0">Startdatum</p>
            <DateInput
              value={formData.startDate}
              name="startDate"
              onChange={handleChange}
            />
            <p className="m0 mtop1">Slutdatum</p>
            <DateInput
              value={formData.endDate}
              name="endDate"
              onChange={handleChange}
            />
            <p className="m0 mtop1">Hotellnätter</p>
            <input
              type="number"
              placeholder="Antal"
              value={formData.hotelNights}
              name="hotelNights"
              onChange={handleChange}
            />
            <p className="m0 mtop1">Mil</p>
            <input
              type="number"
              placeholder="Antal"
              value={formData.miles}
              name="miles"
              onChange={handleChange}
            />
            <p className="m0 mtop1">Ansvarig(a) servicetekniker</p>
            {/* eslint-disable-next-line */}
            <select value="" onChange={addTechnician}>
              <option value="" disabled>
                Välj person
              </option>
              {users.map((user) => (
                <option key={user._id} value={JSON.stringify(user)}>
                  {user.firstName + ' ' + user.lastName}
                </option>
              ))}
            </select>
            {formData.technicians.map((t, i) => (
              <div key={`${t._id}_${i}`} className="technician">
                <p>
                  {t.firstName} {t.lastName}
                </p>
                {/* eslint-disable-next-line */}
                <div
                  className="remove-technician"
                  onClick={() => removeTechnician(i)}
                >
                  <Plus cross color={colors.red} />
                </div>
              </div>
            ))}
            <br />
            <br />
            <p className="mtop2 strong mbottom1">Rader</p>
            <hr />
            <br />
            {formData.rows.map((row, i) => (
              <div className="row" key={`row__${i}`}>
                <div className="first-section">
                  <div>
                    <p className="m0">Reservoar</p>
                    {/* eslint-disable-next-line */}
                    <select
                      required
                      name="reservoir"
                      value={row.reservoir ? JSON.stringify(row.reservoir) : ''}
                      onChange={(e) => handleChangeRowReservoir(e, i)}
                    >
                      <option value="" disabled>
                        Välj reservoar
                      </option>
                      {customer?.reservoirs?.map((reservoir, resIndex) => (
                        <option
                          key={`resopt_${reservoir._id}_${resIndex}`}
                          value={JSON.stringify(reservoir)}
                        >
                          {reservoir.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <p className="m0 mtop1">Datum</p>
                    <DateInput
                      value={row.date}
                      name="date"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                  <div>
                    <p className="m0 mtop1">Tjänst</p>
                    {/* eslint-disable-next-line */}
                    <select
                      value={row.service}
                      onChange={(e) => handleChangeRow(e, i)}
                      name="service"
                    >
                      <option value="">Välj tjänst</option>
                      {services.map((s, i) => (
                        <option value={s} key={`servopt_${s}_${i}`}>
                          {s}
                        </option>
                      ))}
                      <option value="Free">Fritext</option>
                    </select>
                    {row.service === 'Free' && (
                      <input
                        type="text"
                        value={row.serviceFreeText}
                        name="serviceFreeText"
                        onChange={(e) => handleChangeRow(e, i)}
                      />
                    )}
                  </div>
                </div>
                <div className="second-section">
                  <div>
                    <p className="m0 mtop1">Etabl.</p>
                    <input
                      type="number"
                      placeholder="Timmar"
                      value={row.establishmentTimeHours}
                      name="establishmentTimeHours"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                  <div>
                    <p className="m0 mtop1">Reng.</p>
                    <input
                      type="number"
                      placeholder="Timmar"
                      value={row.cleaningHours}
                      name="cleaningHours"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                  <div>
                    <p className="m0 mtop1">Stillest.</p>
                    <input
                      type="number"
                      placeholder="Timmar"
                      value={row.stillStandHours}
                      name="stillStandHours"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                  <div>
                    <p className="m0 mtop1">F125</p>
                    <input
                      type="number"
                      placeholder="Antal"
                      value={row.filter125}
                      name="filter125"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                  <div>
                    <p className="m0 mtop1">F200</p>
                    <input
                      type="number"
                      placeholder="Antal"
                      value={row.filter200}
                      name="filter200"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                  <div>
                    <p className="m0 mtop1">S-filter</p>
                    <input
                      type="number"
                      placeholder="Antal"
                      value={row.scandFilter}
                      name="scandFilter"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                  <div>
                    <p className="m0 mtop1">M125</p>
                    <input
                      type="number"
                      placeholder="Antal"
                      value={row.membrane125}
                      name="membrane125"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                  <div>
                    <p className="m0 mtop1">M200</p>
                    <input
                      type="number"
                      placeholder="Antal"
                      value={row.membrane200}
                      name="membrane200"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                  <div>
                    <p className="m0 mtop1">Skylt</p>
                    <input
                      type="number"
                      placeholder="Antal"
                      value={row.signTest}
                      name="signTest"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                  <div>
                    <p className="m0 mtop1">Tät.</p>
                    <input
                      type="number"
                      placeholder="Meter"
                      value={row.sealMeters}
                      name="sealMeters"
                      onChange={(e) => handleChangeRow(e, i)}
                    />
                  </div>
                </div>
                {formData.rows.length > 1 && (
                  <Button
                    type="button"
                    look="danger"
                    small
                    onClick={() => deleteRow(i)}
                  >
                    Ta bort rad
                  </Button>
                )}
                <hr />
              </div>
            ))}
            <Button type="button" small onClick={addRow}>
              Lägg till rad
            </Button>
            <br />
            <hr />
            <Button type="submit" med>
              Spara
            </Button>
          </form>
        </OverlayBox>
        <OverlayBox
          header="Radera servicelogg?"
          active={deleteLogModal.active}
          close={() => setDeleteLogModal({ ...deleteLogModal, active: false })}
        >
          <p>
            Vill du verkligen <strong>permanent</strong> radera serviceloggen.
            <br />
            Det går <strong>inte</strong> att återskapa en raderad servicelogg.
          </p>
          <Button small look="danger" onClick={deleteLog}>
            Radera
          </Button>
          <Button
            small
            onClick={() =>
              setDeleteLogModal({ ...deleteLogModal, active: false })
            }
          >
            Avbryt
          </Button>
        </OverlayBox>
      </StyledServicelogsPage>
    </Layout>
  );
};

export default ServicelogsPage;
