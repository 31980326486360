import React from 'react';
import styled from 'styled-components';
import DotsDropdown from './DotsDropdown';
import Button from './Button';
import Card from './Card';
import { parseDate } from '../utils/parseDate';

const StyledServiceLogCard = styled.div``;

const ServicelogCard = ({ log, deleteLog, editLog, exportLog }) => {
  return (
    <StyledServiceLogCard>
      <Card className="card-grid">
        <p className="mobile">{parseDate(log.startDate)}</p>
        <p>{parseDate(log.endDate)}</p>
        <p>
          {log.technicians.map((technician, i) => (
            <React.Fragment key={`technician_${log._id}_${i}`}>
              {technician.firstName} {technician.lastName}
              <br />
            </React.Fragment>
          ))}
        </p>
        <p className="mobile">{log.rows.length}</p>
        <DotsDropdown
          content={
            <>
              <Button small onClick={() => exportLog(log, false)}>
                Visa
              </Button>
              <Button small onClick={() => exportLog(log, true)}>
                Ladda ned
              </Button>
              <Button small onClick={() => editLog(log)}>
                Redigera
              </Button>
              <Button small onClick={() => deleteLog(log)} look="danger">
                Radera
              </Button>
            </>
          }
        />
      </Card>
    </StyledServiceLogCard>
  );
};

export default ServicelogCard;
